<template>
  <div
      v-loading="loading"
      element-loading-text="数据加载中……"
      element-loading-spinner="el-icon-loading">
    <el-row>
      <el-col :span="24">
        <div class="default-table tableBox">
          <el-table
              :data="tableData"
              style="width: 100%"
              class="default-table"
              :row-class-name="rowClassName"
              height="100%"
              border>
            <!--            <el-table-column-->
            <!--                prop="index"-->
            <!--                label="#"-->
            <!--                min-width="50">-->
            <!--            </el-table-column>-->
            <!--            <el-table-column-->
            <!--                prop="platform_name"-->
            <!--                label="平台"-->
            <!--                min-width="80" align="center">-->
            <!--            </el-table-column>-->
            <el-table-column
                prop="time_day"
                label="日期"
                min-width="100" align="center">
            </el-table-column>
            <el-table-column
                prop="group_name"
                label="部门-组"
                min-width="100" align="center">
            </el-table-column>
            <el-table-column
                prop="nickname"
                label="红人昵称"
                min-width="100" header-align="center" align="center">
            </el-table-column>
            <template v-for="(column,index) in columns">
              <el-table-column :prop="`${column.value}`" :key="index" :label="`${column.title}`"
                               :min-width="`${column.width}`" align="right" header-align="center">
                <template slot-scope="scope">
                  <span>{{ scope.row[column.value] || 0 }}</span>
                </template>
              </el-table-column>
            </template>
          </el-table>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
// import {getMonthDays} from "@/utils/utils";
import EditableCell from "@/components/EditableCell";
import {numberFormat} from "@/utils/utils";

export default {
  name: "TrendDataRecord",
  components: {
    EditableCell
  },
  props: {
    readOnly: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    searchCondition: {
      type: Object,
      default: () => {
        return {
          group_id: 'group_1_1',
          group_name: '一部一组',
          artist_id: 1,
          nickname: '怡儿',
          platform_code: 'douyin',
          platform_name: '抖音',
          ym: '2021-04',
        }
      },
    }
  },
  data() {
    return {
      showEditIcon: false,//是否显示编辑按钮
      loading: false,
      tableData: [],
      loadingStatus: false,
      offset: 0,
      activeNames: [],
      columns: [
        {value: 'total_num', title: '总粉丝数', width: 100},
        {value: 'add_num', title: '新增粉丝数', width: 100},
        {value: 'content_add_num', title: '内容增粉数', width: 100},
        {value: 'promotion_add_num', title: '推广增粉数', width: 100},
        {value: 'promotion_cost', title: '涨粉投放', width: 100},
        {value: 'price', title: '粉丝单价', width: 100},
        { value: 'ad_repair', title: '广告维护', width: 100 },
        { value: 'live_preheat', title: '直播预热', width: 100 },
        {value: 'add_works_num', title: '新增作品数', width: 100},
        {value: 'total_works_num', title: '总作品数', width: 100},
      ],//数据字段
      pagingData: {
        is_show: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0
      },
    }
  },
  methods: {
    moneyFormat(value) { // 金额 格式化
      return numberFormat(value, 2, '.', ',', 'round')
    },
    numFormat(value) { // 数值 格式化
      return numberFormat(value, 0, '.', ',', 'round')
    },
    async initData(searchCondition) {
      this.tableData = []
      if (searchCondition.ym && searchCondition.artist_id && searchCondition.platform_code) {
        // console.log('searchCondition', searchCondition)
        this.loading = true
        //已录入的数据
        let {list} = await this.$api.getTrendDatumMonthList(searchCondition)
        // console.log('list', list)
        this.tableData = list
        // console.log(this.tableData)
        this.loading = false
      }
    },
    //追加一行
    appendLastRow(days, ym) {
      this.tableData = []
      for (let i = 0; i < days; i++) {
        let time_day = ym + '/' + (i + 1)
        let row = {
          'time_day': time_day,
          'group_id': this.searchCondition.group_id,
          'group_name': this.searchCondition.group_name,
          'artist_id': this.searchCondition.artist_id,
          'nickname': this.searchCondition.nickname,
          'platform_code': this.searchCondition.platform_code,
          'platform_name': this.searchCondition.platform_name,
          'total_num': '',
          'add_num': '',
          'content_add_num': '',
          'promotion_add_num': '',
          'promotion_cost': '',
          'price': '',
          'add_works_num': '',
          'total_works_num': '',
        }
        this.tableData.push(row)
      }

    },
    async selectInput(row, field) {
      let data = {}
      if (row.id) {
        data['id'] = row.id;
        data[field] = row[field];
        data._current_field = field;
      } else {
        data = {...row, ...{_current_field: field}}
      }

      let id = await this.$api.saveTrendDatum(data)
      if (id) {
        row.id = id
      } else {
        this.$notify.error('保存失败！')
      }
    },
    rowClassName({row, rowIndex}) {
      // 把每一行的索引放进row.id
      row.index = rowIndex + 1
    }
  }
}
</script>

<style scoped>
.tableBox {
  height: 80vh;
}
</style>
