<template>
  <div>
    <!--    <router-view v-show="$route.matched.length==3"></router-view>-->
    <PageHeaderLayout>
      <div class="main-page-content">
        <el-row class="searchCondition">
          <el-col :span="22">
            <el-form label-width="90px" size="mini" :inline="true">
              <el-row>
                <el-col :span="24">
                  <el-form-item label="月份：">
                    <el-date-picker
                        v-model="searchCondition.ym"
                        type="month"
                        placeholder="选择月" size="small" value-format="yyyy-MM" @change="showRecords" clearable>
                    </el-date-picker>
                  </el-form-item>
                  <el-form-item label="红人：">
                    <el-input type="text" placeholder="红人昵称检索" v-model="searchCondition.nickname" clearable></el-input>
                  </el-form-item>
                  <el-form-item>
                    <el-popover
                        placement="right"
                        width="400"
                        trigger="click"
                    >
                      <el-table :data="gridData" highlight-current-row @row-click="choiceArtist" size="mini">
                        <el-table-column width="150" property="nickname" label="昵称"></el-table-column>
                        <el-table-column width="200" property="group_name" label="所属分组"></el-table-column>
                      </el-table>
                      <el-button slot="reference" type="primary" @click="search">搜索</el-button>
                    </el-popover>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <el-form-item label="分组：" prop="group_id" v-if="groups.length>8">
                    <el-select v-model="searchCondition.group_id" placeholder="请选择所属分组" filterable clearable
                               @change="showGroups">
                      <el-option :label="`${item.display_name}(${item.artists_count || 0})`" :value="item.name"
                                 v-for="(item,idx) in groups"
                                 :key="idx"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="分组：" v-else>
                    <div>
                      <el-radio-group v-model="searchCondition.group_id" @change="showGroups" v-if="groups.length>0"
                                      size="mini">
                        <el-radio-button :label="group.name" v-for="(group,index) in groups" :key="index">
                          {{ group.display_name }}（{{ group.artists_count || 0 }}）
                        </el-radio-button>
                      </el-radio-group>
                      <span v-else>暂无</span>
                    </div>
                  </el-form-item>
                  <el-form-item label="该组下红人：" label-width="120px">
                    <el-radio-group v-model="searchCondition.artist_id" @change="changeArtist" v-if="artists.length>0"
                                    size="mini">
                      <el-radio-button :label="artist.id" v-for="(artist,idx) in artists" :key="idx">{{
                          artist.nickname
                        }}
                      </el-radio-button>
                    </el-radio-group>
                    <span v-else>暂无</span>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </el-col>
          <el-col :span="2" style="text-align: right">
            <el-link :underline="true" type="primary" class="export-excel" @click="exportDownload"
                     v-if="userPermissions.indexOf('trend_export') !== -1"><i
                class="el-icon-download"></i>导出表格
            </el-link>
            <el-tooltip class="item" effect="dark" content="无权限：导出" placement="top-start" v-else>
              <el-link :underline="false" type="info" class="export-excel">
                <i class="el-icon-download"></i>导出表格
              </el-link>
            </el-tooltip>
          </el-col>
        </el-row>
        <el-row :gutter="1">
          <el-col :span="24">
            <el-tabs type="border-card" class="platforms-tab" :stretch="true" :tab-position="tabPosition"
                     style="height: auto;"
                     v-model="searchCondition.platform_code"
                     @tab-click="handleClick" v-if="artistPlatforms.length>0">
              <el-tab-pane :label="platform.name" :name="`${platform.code}`" v-for="(platform,indx) in artistPlatforms"
                           :key="`${platform.code}_${indx}`" v-show="tabNames.indexOf(platform.code)>-1">
                <span slot="label">
                  <div style="display: block;height: auto">
                      <el-avatar shape="square" :size="30" :src="platform.logo_url" @error="true">
                                  <img src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png"/>
                      </el-avatar>
                    <br/>
                    <el-tooltip class="item" effect="dark" :content="`${ platform.name}`" placement="right">
                        <span class="platform_name">{{
                            platform.name && platform.name.length > 2 ? platform.name.substring(0, 2) : platform.name
                          }}</span>
                    </el-tooltip>
                  </div>
                </span>
                <div>
                  <TrendRecord :ref="`trendRecord_${platform.code}`"
                               v-if="searchCondition.platform_code=== platform.code"
                               :searchCondition="searchCondition"></TrendRecord>
                  <br/>
                  <el-row>
                    <el-col :lg="20" :md="24">
                      <div v-if="userPermissions.indexOf('trend_log') != -1">
                        <TrendDataLog :ref="`trendDataLog_${platform.code}`"
                                      v-if="searchCondition.platform_code=== platform.code"
                                      :searchCondition="searchCondition"></TrendDataLog>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </el-tab-pane>

            </el-tabs>
          </el-col>
        </el-row>
      </div>
    </PageHeaderLayout>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PageHeaderLayout from "@/layouts/PageHeaderLayout";
import ApeTable from "@/components/ApeTable";
import ApeDrawer from "@/components/ApeDrawer";
import TrendRecord from "@/pages/trend/data/TrendRecord";
import TrendDataLog from "@/pages/trend/TrendDataLog";
import FileSaver from 'file-saver';

export default {
  name: 'TrendDataList',
  components: {
    PageHeaderLayout,
    ApeTable,
    ApeDrawer,
    TrendRecord,
    TrendDataLog
  },
  data() {
    return {
      groups: [],//当前页面展示的分组列表
      artists: [],//可选红人列表
      tabPosition: 'left',
      loadingStatus: false,
      // 表格列表数据
      dataList: [],
      // 分页信息
      pagingData: {
        is_show: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        page_size: 15
      },
      // 分页的offset,序号列使用
      offset: 0,
      columns: [],
      platforms: [],
      artistPlatforms: [],
      tabNames: [],
      // 抽屉数据
      drawerData: {
        visible: false,
        loading: true,
        loading_text: '玩命加载中……',
        width_height: '480px',
        show_footer: false
      },
      searchCondition: {
        group_id: null,
        artist_id: null,
        nickname: null,
        ym: null,
        platform_code: null,
        platform_name: '',
      },
      gridData: []
    }
  },
  computed: {
    ...mapGetters(['userPermissions', 'buttonType'])
  },
  async created() {
    // 平台
    await this.getPageSettings()
    // 我的分组-》个人配置
  },
  mounted() {
  },
  watch: {
    "$route.matched": function (n) {
      if (n.length === 2) {
        // this.initList()
      }
    }
  },
  methods: {
    async exportDownload() {
      try {
        var isFileSaverSupported = !!new Blob;
        // console.log('isFileSaverSupported', isFileSaverSupported)
        if (isFileSaverSupported) {
          let searchCondition = this.searchCondition
          if (searchCondition.ym && searchCondition.artist_id && searchCondition.platform_code) {
            let response = await this.$api.exportTrendDatumMonthList(searchCondition)
            let data = response.data
            let contentType = response.headers['content-type']
            let name = `涨粉数据[${ searchCondition.ym }_${ searchCondition.platform_name }_${ searchCondition.nickname }]`
            let blob = new Blob([data], {
              type: contentType
            });
            FileSaver.saveAs(blob, decodeURI(name + ".xlsx"), { autoBom: true });
          } else {
            this.$message.warning('筛选条件不符合要求')
          }
        } else {
          this.$message.warning('浏览器不支持导出文件')
        }
      } catch (e) {
        this.$message.warning('导出异常，请联系管理员')
      }

    },
    async search() {
      this.gridData = []
      if (this.searchCondition.nickname) {
        // 更新红人昵称搜索，返回红人ID和分组
        let { list } = await this.$api.searchArtists({ nickname: this.searchCondition.nickname })
        this.gridData = list
      }

    },
    //选择红人后，触发事件
    choiceArtist(row) {
      this.searchCondition.artist_id = row.id;
      this.searchCondition.nickname = row.nickname;
      this.searchCondition.group_id = row.group_id;
      this.searchCondition.group_name = row.group_name;
      this.showGroups()
    },
    getCurrentMonth() {
      let currentDate = new Date()
      this.searchCondition.ym = currentDate.getFullYear() + '-' + (currentDate.getMonth() + 1)
    },
    async showGroups() {
      let group = this.groups.find((value) => value.name === this.searchCondition.group_id)
      this.searchCondition.group_name = group ? group.display_name : ''
      await this.getGroupArtists()
      this.showRecords()
    },
    async changeArtist() {
      // 检索红人昵称
      let artist = this.artists.find((value) => value.id === this.searchCondition.artist_id)
      this.searchCondition.nickname = artist ? artist.nickname : ''

      let tabNames = [];
      let artistPlatforms = [];
      let platformsMap = artist ? artist.platforms : {}
      this.platforms.forEach((currentPlatform) => {
        if (platformsMap[currentPlatform.code] === 'Y') {
          tabNames.push(currentPlatform.code)
          artistPlatforms.push(currentPlatform)
        }
      })

      this.tabNames = tabNames
      this.artistPlatforms = artistPlatforms
      if (this.artistPlatforms.length > 0) {
        this.searchCondition.platform_code = this.artistPlatforms[0].code
        this.searchCondition.platform_name = this.artistPlatforms[0].name
      }

      this.showRecords()
    },
    showRecords() {
      let artistPlatform = this.artistPlatforms.find((value) => value.code === this.searchCondition.platform_code)
      this.searchCondition.platform_name = artistPlatform.name
      this.$nextTick(() => {
        let ref = `trendRecord_${ this.searchCondition.platform_code }`
        if (this.$refs[ref])
          this.$refs[ref][0].initData(this.searchCondition)
      })

    },
    async getPageSettings() {
      //页面配置-依次加载 当前月份-》平台 我的分组-》红人
      await this.getCurrentMonth()
      await this.getPlatforms()
      await this.getMyGroup()
    },
    async getMyGroup() {
      let { groups } = await this.$api.getMyUserMcnGroup('trend_data')
      // // let {groups} = await this.$api.getMyMcnGroup('trend_all_groups')
      // let {list} = await this.$api.getGroupList()
      this.groups = groups
      if (this.groups.length > 0) {
        //有所属分组，默认第一个分组数据展示
        this.searchCondition.group_id = this.groups[0]['name'];
        this.searchCondition.group_name = this.groups[0]['display_name'];
        await this.getGroupArtists()
      } else {
        this.$message.warning('没有所属分组，请获取分组')
      }
    },
    async getGroupArtists() {
      this.searchCondition.artist_id = null
      this.searchCondition.nickname = null
      let groupId = this.searchCondition.group_id
      let { list } = await this.$api.getGroupArtists(groupId)
      this.artists = list
      if (list.length > 0) {
        this.searchCondition.artist_id = list[0].id
        await this.changeArtist()
      }
    },
    async getPlatforms() {
      //从缓存中读取上次的平台信息
      let platformsJson = localStorage.getItem('platforms')
      if (platformsJson.indexOf('{') > -1) {
        this.platforms = JSON.parse(platformsJson)
      }
      // 获取最新平台信息
      let { list } = await this.$api.getPlatforms()
      this.platforms = list
      if (list.length > 0)
        this.searchCondition.platform_code = list[0].code

      // 缓存平台信息
      localStorage.setItem('platforms', JSON.stringify(list))

    },
    async getColumns() {
      let { columns } = await this.$api.getColumnsArtist()
      this.columns = columns
    },
    handleClick() {
      // let artist = this.artists.find((value) => value.id === searchCondition.artist_id)
      let platform = this.platforms.find((value) => value.code === this.searchCondition.platform_code)
      if (platform)
        this.searchCondition.platform_name = platform.name

      // console.log('tab', tab.name, event.type);
      this.showRecords()
    },
    // 处理搜索条件
    handleSearchCondition() {
      let condition = {}

      if (this.searchCondition.platforms) {
        condition.platforms = this.searchCondition.platforms
      }
      if (this.searchCondition.group_id) {
        condition.group_id = this.searchCondition.group_id
      }
      if (this.searchCondition.nickname) {
        condition.nickname = this.searchCondition.nickname
      }
      if (this.searchCondition.sign_status) {
        condition.sign_status = this.searchCondition.sign_status
      }

      if (this.searchCondition.time_value) {
        condition.start_time = this.searchCondition.time_value[0]
        condition.end_time = this.searchCondition.time_value[1]
      }

      return condition
    },
  },
}
</script>

<style scoped>
.searchCondition {
  margin-bottom: 10px;
}
</style>
<style>

.platforms-tab .el-tabs__item {
  display: block;
  margin: 1px 1px;
  line-height: 25px;
  font-size: 1rem;
  /*word-wrap: break-word;*/
  writing-mode: horizontal-tb;
  height: 80px;
  padding-left: 10px;
  padding-top: 10px;
}

.el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
  color: #1890ff;
  background-color: #FFFFFF;
  border-right-color: #DCDFE6;
  border-left-color: #DCDFE6;
  font-weight: 600;
  font-size: 14px;
}

.export-excel, .share-rank {
  margin-top: 20px;
  margin-right: 20px;
  margin-bottom: 1px;
  font-size: 16px;
  font-weight: 600;
}

.el-select > .el-input {
  width: 200px !important;
}
</style>
